import Menu from "@material-ui/core/Menu";
import { useState, cloneElement } from "react";

const Popoverize = ({ children, options, MenuProps, ...rest }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const togglePopUp = (e) => setMenuAnchor(menuAnchor ? null : e.currentTarget);
  const newChildren = cloneElement(children, { onClick: togglePopUp, ...rest });
  
  return (
    <>
      {newChildren}
      <Menu
        onClick={togglePopUp}
        id="menu-appbar"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={togglePopUp}
        {...MenuProps}
      >
        {options}
      </Menu>
    </>
  );
};

export default Popoverize;
